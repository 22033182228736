
import { defineComponent, ref } from "vue";
import ReturnProductDetailDisplay from "../../ReturnProductDetailDisplay.vue";
import ReturnFinanceForm from "./ReturnFinanceForm.vue";

export default defineComponent({
  components: {
    ReturnProductDetailDisplay,
    ReturnFinanceForm,
  },
  emits: ["onSetReturnState"],
  setup(props, { emit }) {
    const isDisplayProduct = ref<boolean>(true);
    const isDisplay = ref<boolean>(false);

    const setReturnState = () => {
      isDisplay.value = true;
      isDisplayProduct.value = false;
    };

    const onSetReturnState = () => {
      emit("onSetReturnState", 3);
    };

    return { isDisplayProduct, isDisplay, setReturnState, onSetReturnState };
  },
});
