import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReturnRepuestForm = _resolveComponent("ReturnRepuestForm")!
  const _component_ReturnRequestSummary = _resolveComponent("ReturnRequestSummary")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.returnState == 1)
      ? (_openBlock(), _createBlock(_component_ReturnRepuestForm, {
          key: 0,
          onSetReturnStep: _ctx.setReturnStep,
          isDisplay: false
        }, null, 8, ["onSetReturnStep"]))
      : _createCommentVNode("", true),
    (_ctx.returnState == 2)
      ? (_openBlock(), _createBlock(_component_ReturnRequestSummary, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}