
import { defineComponent, ref } from "vue";
import ReturnRepuestForm from "./component/ReturnRepuestForm.vue";
import ReturnRequestSummary from "../ReturnRequestSummary.vue";

export default defineComponent({
  components: {
    ReturnRepuestForm,
    ReturnRequestSummary,
  },
  setup() {
    const returnState = ref<number>(1);
    const setReturnStep = (value: number) => {
      returnState.value = value;
    };
    return { returnState, setReturnStep };
  },
});
