
import { ImageResponse } from "@/types/master/Image.interface"
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"


export default class MasterService {

    async onSaveImage(img?: string): Promise<ImageResponse> {
        return await axios.post('/upload/Save', { base64: img })
            .then((res: ResponseData<ImageResponse>) => {
                if (res.data.result == 1) {
                    return res.data as ImageResponse
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
}
