
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      required: false,
      type: String,
    },
    text: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    modelValue: {
      required: false,
      type: [String, Number],
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    valueField: {
      required: false,
      type: String,
    },
    textField: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      type: String,
    },
  },
  emits: ["onDataChange"],
  setup(props, { emit }) {
    const onDataChange = (event: any) => {
      emit("onDataChange", event);
    };
    return { onDataChange };
  },
});
