
import { defineComponent, ref } from "vue";
import { FileType } from "@/types/master/FileType.interface";
import InputText from "primevue/inputtext";
import MasterService from "@/service/master-service/MasterService";
import { ImageResponse } from "@/types/master/Image.interface";

export default defineComponent({
  props: {
    dataList: {
      required: false,
      type: Array,
    },
    required: {
      required: false,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
    urlkey: {
      required: false,
      type: String,
    },
    index: {
      required: false,
      type: Number,
    },
    optionIndex: {
      required: false,
      type: Number,
    },
    v: {
      required: false,
      type: Object,
    },
    languageActiveId: {
      required: false,
      type: Number,
    },
    sameReplyAsNeutral: {
      required: false,
      type: Boolean,
    },
    isDisplay: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const file = ref<string>("");
    const value = ref<string>("");
    const type = ref<FileType>({
      all: ["image/jpeg", "image/png", "application/pdf", ""],
      file: ["image/jpeg", "image/png", "application/pdf"],
      pdf: ["application/pdf"],
      png: ["image/png"],
      image: ["image/jpeg", "image/png"],
    });
    const error = ref<string>("");
    const hasError = ref<boolean>(false);
    const input = ref();
    const masterService = new MasterService();
    const imageList = ref<string[]>([]);

    const onFileChange = (
      e: UIEvent & { target: HTMLInputElement & { files: Array<string> } }
    ) => {
      const file = e.target.files[0];
      var _validFileExtensions = ["image/jpeg", "image/png"];

      if (e.target.files.length) {
        if (_validFileExtensions.indexOf(file.type) < 0) {
          input.value = "";
        } else if (file.size > 10000000) {
          console.log(">10000000");
        } else {
          handleChangeFileImage(file);
        }
      } else {
        input.value = "";
      }
    };
    const handleChangeFileImage = async (value: any) => {
      if (value) {
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          value.value = reader.result;
          saveImage(reader.result?.toString());
        };
      } else {
        value.value = "";
      }
    };
    const saveImage = async (value?: string) => {
      let image = (await saveImagetoDb(value)) as string;
      if (image) {
        imageList.value.push(image);
      }

      emit("handleChangeImageList", imageList);
    };
    const saveImagetoDb = async (img?: string) => {
      return masterService.onSaveImage(img).then((data: ImageResponse) => {
        if (data.result == 1) {
          return data.detail.url;
        } else {
          return data.detail;
        }
      });
    };
    return { input, onFileChange };
  },
});
