
import { defineComponent, ref } from "vue";
import UploadFilePanel from "@/components/return/component/UploadFileImage.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputTextCustom from "@/components/inputs/InputText.vue";

export default defineComponent({
  components: {
    UploadFilePanel,
    InputSelect,
    InputTextCustom,
  },
  props: {
    isDisableShipping: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const onImageChange = (value: any) => {
      console.log("onImageChange");
    };

    const shippingList = ref([
      {
        id: 1,
        name: "Kerry",
      },
    ]);

    const form = ref({
      Attachment: [],
      shippingId: 0,
      trackingNo: "",
      shippingAmount: 0,
    });

    const onShippingChange = (event: any) => {
      form.value.shippingId = event.valu;
    };
    return { form, shippingList, onImageChange, onShippingChange };
  },
});
