import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59545311"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "text-error"
}
const _hoisted_6 = {
  key: 1,
  class: "text-error"
}
const _hoisted_7 = {
  key: 2,
  class: "text-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['select-custom', { error: _ctx.isValidate }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("br", _hoisted_3))
      : _createCommentVNode("", true),
    _createVNode(_component_Dropdown, {
      name: _ctx.name,
      options: _ctx.options,
      modelValue: _ctx.modelValue,
      optionLabel: _ctx.textField,
      optionValue: _ctx.valueField,
      disabled: _ctx.isDisplay,
      placeholder: _ctx.placeholder,
      onChange: _ctx.onDataChange,
      class: "w-100 dropdown-select-custom",
      ref: "input"
    }, null, 8, ["name", "options", "modelValue", "optionLabel", "optionValue", "disabled", "placeholder", "onChange"]),
    (_ctx.v && _ctx.v.$error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.v.required && _ctx.v.required.$invalid == true)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.options[0][_ctx.textField || "text"]), 1))
            : (_ctx.v.minValue && _ctx.v.minValue.$invalid == true)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.options[0][_ctx.textField || "text"]), 1))
              : _createCommentVNode("", true),
          (_ctx.v.valueDefault && _ctx.v.valueDefault.$invalid == true)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.options[0][_ctx.textField || "text"]), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}