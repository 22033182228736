import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReturnDownloadForm = _resolveComponent("ReturnDownloadForm")!
  const _component_ReturnSendProductPanel = _resolveComponent("ReturnSendProductPanel")!
  const _component_ReturnRequestSummary = _resolveComponent("ReturnRequestSummary")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.returnState == 1)
      ? (_openBlock(), _createBlock(_component_ReturnDownloadForm, {
          key: 0,
          onOnSetReturnState: _ctx.onSetReturnState
        }, null, 8, ["onOnSetReturnState"]))
      : _createCommentVNode("", true),
    (_ctx.returnState == 2)
      ? (_openBlock(), _createBlock(_component_ReturnSendProductPanel, {
          key: 1,
          onOnSetReturnState: _ctx.onSetReturnState
        }, null, 8, ["onOnSetReturnState"]))
      : _createCommentVNode("", true),
    (_ctx.returnState == 3)
      ? (_openBlock(), _createBlock(_component_ReturnRequestSummary, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}