
import { defineComponent, ref } from "vue";
import ReturnProductDetailDisplay from "../../ReturnProductDetailDisplay.vue";
import ReturnRequestDetail from "../component/ReturnRequestDetail.vue";
import ReturnContactPanel from "../component/ReturnContactPanel.vue";
import ReturnProductDetail from "../../ReturnProductDetail.vue";

export default defineComponent({
  components: {
    ReturnProductDetailDisplay,
    ReturnRequestDetail,
    ReturnContactPanel,
    ReturnProductDetail,
  },
  props: {
    isDisplay: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["setReturnStep"],
  setup(props, { emit }) {
    const onImageChange = (value: any) => {
      console.log("onImageChange");
    };
    const requestStep = ref<number>(1);
    const setReturnStep = () => {
      emit("setReturnStep", 2);
    };
    return { onImageChange, requestStep, setReturnStep };
  },
});
