
import { defineComponent, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";

export default defineComponent({
  components: {
    InputTextCustom,
  },
  props: {
    isDisplay: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const form = ref({
      email: "",
      telephone: "",
    });
    const isCollapsed = ref<boolean>(false);

    const handlerZipcode = (event: any) => {
      if (!form.value) return;
      form.value.telephone = event.replace(/[^0-9]+/g, "");
    };

    return { form, isCollapsed , handlerZipcode };
  },
});
