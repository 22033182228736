
import { defineComponent } from "vue";
import ReturnRequestPanel from "@/components/return/return-request/ReturnRequestPanel.vue";

export default defineComponent({
  components: {
    ReturnRequestPanel,
  },
  setup() {
    return {};
  },
});
