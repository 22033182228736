import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a82618a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-y-auto h-100vh" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReturnRequestPanel = _resolveComponent("ReturnRequestPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ReturnRequestPanel)
  ]))
}