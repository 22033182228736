
import { defineComponent, ref } from "vue";
import ReturnDownloadForm from "./component/ReturnDownloadForm.vue";
import ReturnSendProductPanel from "./component/ReturnSendProductPanel.vue";
import ReturnRequestSummary from "../ReturnRequestSummary.vue";

export default defineComponent({
  components: {
    ReturnDownloadForm,
    ReturnSendProductPanel,
    ReturnRequestSummary,
  },
  setup() {
    const returnState = ref<number>(1);
    const isDisplayProduct = ref<boolean>(true);
    const isDisplay = ref<boolean>(false);
    const onSetReturnState = (state: number) => {
      returnState.value = state;
    };
    return { returnState, isDisplayProduct, isDisplay, onSetReturnState };
  },
});
