
import { defineComponent, ref } from "vue";
import UploadFilePanel from "@/components/return/component/UploadFileImage.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputTextCustom from "@/components/inputs/InputText.vue";

export default defineComponent({
  components: {
    UploadFilePanel,
    InputSelect,
    InputTextCustom,
  },
  props: {
    isDisplay: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const onImageChange = (value: any) => {
      console.log("onImageChange");
    };

    const returnReason = ref([
      {
        id: 1,
        name: "สินค้าชำรุด",
      },
    ]);
    //const Attachment = ref([]);
    const form = ref({
      Attachment: [],
      returnReasonId: 0,
      returnNote: "",
    });
    const isCollapsed = ref<boolean>(false);

    const onReturnResonChange = (event: any) => {
      form.value.returnReasonId = event.valu;
    };
    return {
      onImageChange,
      form,
      returnReason,
      onReturnResonChange,
      isCollapsed,
    };
  },
});
