
import { defineComponent, ref } from "vue";
import InputTextCustom from "@/components/inputs/InputText.vue";

export default defineComponent({
  components: {
    InputTextCustom,
  },
  props: {
    isDisplay: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const form = ref({
      email: "",
      telephone: "",
    });
    const isCollapsed = ref<boolean>(false);

    return { form, isCollapsed };
  },
});
