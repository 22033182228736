
import { defineComponent, ref } from "vue";
import ReturnRepuestForm from "../../step1/component/ReturnRepuestForm.vue";

export default defineComponent({
  components: {
    ReturnRepuestForm,
  },
  emits: ["onSetReturnState"],
  setup(props, { emit }) {
    const sumaryState = ref<number>(1);
    const setSummaryState = (value: number) => {
      sumaryState.value = value;
    };
    const onSetReturnState = () => {
      emit("onSetReturnState", 2);
    };
    return { sumaryState, setSummaryState, onSetReturnState };
  },
});
