import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReturnRequestStep1 = _resolveComponent("ReturnRequestStep1")!
  const _component_ReturnRequestStep2 = _resolveComponent("ReturnRequestStep2")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.returnRequestStep == 1)
      ? (_openBlock(), _createBlock(_component_ReturnRequestStep1, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.returnRequestStep == 2)
      ? (_openBlock(), _createBlock(_component_ReturnRequestStep2, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}