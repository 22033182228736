
import { defineComponent, ref } from "vue";
import ReturnRequestStep1 from "./step1/ReturnRequestStep1.vue";
import ReturnRequestStep2 from "./step2/ReturnRequestStep2.vue";

export default defineComponent({
  components: {
    ReturnRequestStep1,
    ReturnRequestStep2,
  },

  setup(props, { emit }) {
    const returnRequestStep = ref<number>(2);
    return { returnRequestStep };
  },
});
