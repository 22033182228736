
import { defineComponent, ref } from "vue";
import ReturnRepuestForm from "./step1/component/ReturnRepuestForm.vue";

export default defineComponent({
  components: {
    ReturnRepuestForm,
  },
  setup() {
    const sumaryState = ref<number>(1);
    const setSummaryState = (value: number) => {
      sumaryState.value = value;
    };
    return { sumaryState, setSummaryState };
  },
});
