
import { defineComponent, ref } from "vue";
import ReturnSendProductForm from "../return-request/step2/component/ReturnSendProductForm.vue";

export default defineComponent({
  props: {
    isDisplayShipProductForm: {
      type: Boolean,
      required: false,
    },
    isDisplayProduct: {
      type: Boolean,
      required: false,
    },
    isDisableShipping: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    ReturnSendProductForm,
  },
  setup() {
    const isCollapsed = ref<boolean>(false);
    return { isCollapsed };
  },
});
